import React from 'react'
// import Carousels from '../../Components/Carousels'

function Details() {
  return (
    <div>
      Details
      {/* <Carousels/> */}
    </div>
  )
}

export default Details
